import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import {
  BehaviorSubject, Observable, throwError as observableThrowError
} from "rxjs";
import { catchError, filter, mergeMap, take } from "rxjs/operators";
import { AppConfig } from "../../app.config";
import { OauthService } from "../../services/oauth/oauth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _oauthService: OauthService;

  private refreshTokenInProgress = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  get oauthService() {
    if (!this._oauthService) {
      this._oauthService = this.injector.get(OauthService);
    }

    return this._oauthService;
  }

  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = request.headers;

    const notUrlEnconded =
      headers.get("Content-Type") !== "application/x-www-form-urlencoded";

    const notOctetStream =
      headers.get("Content-Type") !== "application/octet-stream";

    const notFormData = headers.get("Content-Type") !== "multipart/form-data";

    if (notUrlEnconded && notOctetStream && notFormData) {
      request = this.addAuthenticationToken(request);
    }

    if (this.isRequestMultiPart(request)) {
      request = request.clone({ headers: AppConfig.getHttpHeader() });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        const isUrlEncoded =
          request.headers.get("Content-Type") ===
          "application/x-www-form-urlencoded";

        const isOctetStream =
          request.headers.get("Content-Type") === "application/octet-stream";

        if (isUrlEncoded || isOctetStream) {
          return observableThrowError(error);
        }

        if (error.status !== 401) {
          return observableThrowError(error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            mergeMap(() => next.handle(this.addAuthenticationToken(request)))
          );
        }

        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);

        return this.refreshToken().pipe(
          mergeMap((token) => {
            AppConfig.OAUTH_DATA = token;

            localStorage.setItem("@QueueManager:oauth", JSON.stringify(token));

            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);

            return next.handle(this.addAuthenticationToken(request));
          }),
          catchError((_) => {
            this.refreshTokenInProgress = false;
            this.oauthService.doLogout();

            return observableThrowError(error);
          })
        );
      })
    );
  }

  private refreshToken() {
    const refreshToken = AppConfig.OAUTH_DATA
      ? AppConfig.OAUTH_DATA.refresh_token
      : null;
    return this.oauthService.refreshToken(refreshToken);
  }

  private addAuthenticationToken(request: HttpRequest<any>) {
    if (!AppConfig.OAUTH_DATA) {
      return request;
    }

    const userSchema = request.headers.get("schema");

    const authHeader = userSchema
      ? AppConfig.getAuthorizationHeaderWithSchema(userSchema)
      : AppConfig.getAuthorizationHeader();

    return request.clone({ headers: authHeader });
  }

  /**
   * Retorna se o content type do request é multipart/form-data.
   */
  private isRequestMultiPart(request) {
    return this.getContentType(request) === "multipart/form-data";
  }

  /**
   * Retorna o content type do request passado.
   */
  private getContentType(request) {
    return request.headers.get("Content-Type");
  }
}
